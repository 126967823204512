import React from 'react';
import GoogleCalendarTabs from "../GoogleCalendarTabs/GoogleCalendarTabs";

const GoogleSchedule = () => {
    return (
        <div>
            <GoogleCalendarTabs/>
        </div>
    );
};

export default GoogleSchedule;
